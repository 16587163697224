import * as React from "react"
import { Link } from "gatsby"

const NotFoundPage = () => {
  return (
    <main className="not-found__main">
      <h1 className="not-found__title white">OOPS!</h1>
      <span className="not-found__sub white">ALGO ESTÁ ERRADO</span>
      <Link to="/" className="not-found__back white">
        VOLTE AO INÍCIO
      </Link>
    </main>
  )
}

export default NotFoundPage
